.item {
    background-color: transparent;
    padding: 15px 6px;
}

.itemSelectable {
    background-color: transparent;
    padding: 15px 6px;
    cursor: pointer;
    border-radius: 6px;
    transition: 0.225s;
}

.itemSelectable:hover {
    background-color: #F6F6F6;
}

.itemSelected {
    background-color: #E9E9E9;
    padding: 15px 6px;
    cursor: pointer;
    border-radius: 6px;
    transition: 0.225s;
}