.navlogo {
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-weight: bold;
    cursor: pointer;
    line-height: 20px;
}
.navbutton {
    color: #FAFAFA;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 600;
    line-height: 26px; 
    cursor: pointer;
}
.navbuttoncurrent {
    color: #FAFAFA;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 600;
    line-height: 26px; 
    cursor: pointer;
    text-decoration: underline;
}
.navbutton:hover {
    color: #FFFFFF;
    text-decoration: underline;
}
.navbutton:active {
    text-decoration: underline;
}   