.noticelistitem {
    background-color: transparent;
    padding: 15px 6px;
    cursor: pointer;
    transition: 0.225s;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
}

.noticelistitem:hover {
    background-color: #F6F6F6;
    cursor: pointer;
}