
.breakdown-table {
    width: 250px;
    border-collapse: collapse;
}

.type-col {
    width: 20px;

}

.detail1-col {
    padding-left: 20px;
}

.detail2-col {

}

.subsum-row,.total-row {
    border-top: 1px solid;
}

.subsum-row, .perrecipient-row {
    font-style: italic;
}

.total-row {
    font-weight: bold;
}

.perrecipient-row .type-col {
    padding-left: 20px;
}

.price-col {
    min-width: 80px;
    text-align: right;
}
