/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 19, 2018 */



@font-face {
    font-family: 'Lato';
    src: url('lato-bold-webfont.woff2') format('woff2'),
         url('lato-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'Lato';
    src: url('lato-italic-webfont.woff2') format('woff2'),
         url('lato-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Lato';
    src: url('lato-regular-webfont.woff2') format('woff2'),
         url('lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}